import React from 'react'

export default function SectionWaiting() {
    return (
        <div className='container mt-80'>
            <div className="waiting-card">
                <div className="set-trans-bg  perfect-hero-card px-30">
                <div class="text-start"><h2 class="big-middel-heading color-dark mb-1">What are you waiting for?</h2><p class="color-dark font-500 line-20 fs-18 op-70">Enter the registration number to check <br /> 
                car details instantly</p></div>
                <div className="d-flex align-col-btn gap-2 mt-50">
                    <div className="br-input-align">
                    <input
                        type="text"
                        className="form-control input-pt-set"
                        placeholder="Enter License Plate"
                    />
                    </div>
         
                    <button class="gray-btn set-blue-btn  border-0  btnyellow"><span class="w-100">Get an instant Price</span></button>
                    </div>
                </div>
            </div>
            <div className="row align-items-center justify-content-center">
                <div className="col-12">
                    <p className='color-dark heding-normal opacity-75 mt-20'>
                        * Price excludes tax, title, tags and $399 CarMax processing fee (not required by law). Price assumes that final purchase will be made in the State of KS, unless vehicle is non-transferable. Vehicle subject to prior sale. Applicable transfer fees are due in advance of vehicle delivery and are separate from sales transactions. Limited warranty is 90-Day/4,000-Miles, whichever comes first. See store for written details. We make every effort to provide accurate information, but please verify before purchasing.
                    </p>
                </div>
            </div>
            <div className='mt-50'></div>
        </div>
    )
}
