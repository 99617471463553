import React, { useState } from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";

export default function SectionHeroTrack() {
  const [inputs, setInputs] = useState({
    vin_number: "",
    zip_code: "",
  });
  const [validations, setValidations] = useState({
    vin_number: false,
    zip_code: false,
  });
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "zip_code" && !/^[0-9]+$|^$/.test(value)) return;
    setInputs((prev) => ({ ...prev, [name]: value }));
    if (validations[name])
      setValidations((prev) => ({ ...prev, [name]: false }));
  };

  const checkValidations = () => {
    if (!inputs.vin_number) {
      setValidations((prev) => ({ ...prev, vin_number: true }));
      return;
    }
    if (!inputs.zip_code) {
      setValidations((prev) => ({ ...prev, zip_code: true }));
      return;
    }
    navigate("/get-offer", { state: inputs });
  };

  return (
    <div className="hero-section">
      <div className="container">
        <Header className={"light-nav"} />
        <div className="row mt-140">
          <div className="col-12 pb-5">
            <h1 className="main-heading">
              Want to sell your car?
              <br className="d-lg-block" />
              <span className="color-yellow">
                Get a True Cash Offer in Minutes.
              </span>
            </h1>
            <p className="mb-0 color-light-gray opacity-75">
              Answer a few questions about your vehicle, and then connect with
              one of thousands of <br />
              Certified Dealers who can pay you directly for your used car.
            </p>
          </div>
          <div className="col-7 mt-50">
            <div className="yellow-card perfect-hero-card px-30">
              <ul
                className="nav nav-pills mb-3 perfect-tab"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    VIN
                  </button>
                </li>
                <li className="nav-item ms-2" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Licence Plate
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex={0}
                >
                  <div className="row mb-3">
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ENTER VIN NO."
                        name="vin_number"
                        value={inputs.vin_number}
                        onChange={handleInputChange}
                      />
                      {validations.vin_number && (
                        <div className="err_msg">Please enter VIN number</div>
                      )}
                    </div>
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ZIP CODE"
                        name="zip_code"
                        value={inputs.zip_code}
                        onChange={handleInputChange}
                      />
                      {validations.zip_code && (
                        <div className="err_msg">Please enter ZIP code</div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex gap-3 align-items-center">
                    <button
                      className="gray-btn border-0 btnyellow"
                      onClick={checkValidations}
                    >
                      <span className="px-3">Get you Offer</span>
                    </button>
                    <div>
                      <p className="mb-1">Is this vehicle a lease?</p>
                      <span className="color-yellow pointer">
                        Learn More &#62;
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex={0}
                >
                  no
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
