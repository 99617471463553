import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { Fb, GrayUser, Insta, Messageicone, Tweet } from '../components/svg'
import SectionHero from '../components/SectionHero'
import SectionWaiting from '../components/SectionWaiting'
import Box from '../components/Box'
import CarGuide from '../components/CarGuide'

const blogdetail = [{
    name: "Jennifer Reeves",
    date: 'March,19 2024',
    title: 'Where Did the Colorful Cars Go?',
    description: 'Over three-quarters of all vehicles sold in North America are achromatic. What happened to color?',
    link: '/blog-detail',
    img: './assets/img/blog/bloglist/1.webp'
},
{
    name: "Jennifer Reeves",
    date: 'March,15 2024',
    title: '2025 Toyota 4Runner Unveiled With Turbo Power, Classic Style',
    description: 'Revamped for the first time in over a decade, the mid-size SUV stays true to its four-wheeling mission.',
    link: '/blog-detail',
    img: './assets/img/blog/bloglist/2.webp'
},
{
    name: "Jennifer Reeves",
    date: 'March,15 2024',
    title: '2024 Lexus GX vs. 2024 Lexus TX',
    description: 'There’s a lot of overlap between Lexus’s three-row SUVs, but there are meaningful differences too.',
    link: '/blog-detail',
    img: './assets/img/blog/bloglist/3.webp'
},
{
    name: "Jennifer Reeves",
    date: 'March,13 2024',
    title: 'Guide To Finding the Right Size SUV or Crossover for You',
    description: 'It’s easy to go overboard when you’re looking at SUVs and crossovers.',
    link: '/blog-detail',
    img: './assets/img/blog/bloglist/4.webp'
},
{
    name: "Jennifer Reeves",
    date: 'March,12 2024',
    title: '2024 Chevrolet Silverado 1500 Trim Levels: What You Get',
    description: 'Truck lineups are often difficult to parse. This guide aims to help.',
    link: '/blog-detail',
    img: './assets/img/blog/bloglist/5.webp'
},
{
    name: "Jennifer Reeves",
    date: 'March,04 2024',
    title: 'Car Dealership Checklist: What to Bring and What to Do at the Dealership',
    description: 'Get peace of mind, one item at a time, with our helpful dealership checklist.',
    link: '/blog-detail',
    img: './assets/img/blog/bloglist/6.webp'
}
]
const sconddetail = [
    {
        name: "Jennifer Reeves",
        date: 'February,29 2024',
        title: 'The Best Car Cleaning Kits',
        description: 'Keep your car looking showroom fresh with these car wash kits.',
        link: '/blog-detail',
        img: './assets/img/blog/bloglist/7.webp'
    },
    {
        name: "Jennifer Reeves",
        date: 'February,20 2024',
        title: '5 Best Phone Holders for Your Car',
        description: 'Stay connected and stay safe on the road with these phone mounts.',
        link: '/blog-detail',
        img: './assets/img/blog/bloglist/8.webp'
    },
    {
        name: "Jennifer Reeves",
        date: 'February,18 2024',
        title: 'The Best Phone Chargers for Your Car',
        description: 'Here are top picks for powering your devices on the road.',
        link: '/blog-detail',
        img: './assets/img/blog/bloglist/9.webp'
    },
    {
        name: "Jennifer Reeves",
        date: 'February,17 2024',
        title: '2025 Infiniti QX80 First Look: Big Three-Row SUV Finally Feels Like a Flagship',
        description: 'Infiniti loads a new version of its biggest, and priciest, model with plenty of tech',
        link: '/blog-detail',
        img: './assets/img/blog/bloglist/10.webp'
    },
    {
        name: "Jennifer Reeves",
        date: 'February,12 2024',
        title: '4WD and AWD: Do You Need Them?',
        description: 'Understand the difference and whether they’re worth the extra cost.',
        link: '/blog-detail',
        img: './assets/img/blog/bloglist/11.webp'
    },
    {
        name: "Jennifer Reeves",
        date: 'February,07 2024',
        title: 'Everything You Need To Know About EV Tires',
        description: 'Most tires will work just fine on an EV, but those designed specifically for the task will work better.',
        link: '/blog-detail',
        img: './assets/img/blog/bloglist/12.webp'
    }
]
export default function Blog() {
    const navigate = useNavigate()
    return (
        <>
            <div className='perfect-body'>
                <div className='container'>
                    <Header />
                    <div className='mt-100'>
                        <div className="text-center">
                            <h1 className='mb-3 main-heading color-dark'>
                                Insight and Updates
                            </h1>
                            <p className='mb-3 color-dark opacity-75'>
                                A collection of hand-picked articles for carMax found out what this extra money means for people who’ve sold their car on CarMax.<br />
                                Meet real customers, who all made the most of selling their cars the more money way.
                            </p>
                        </div>
                        <div className="mt-60">
                            <div className="row gap_20">
                                {blogdetail.map((i, index) =>
                                    <div className="col-lg-6" key={index}>
                                        <div className="story-border-card">
                                            <div className='d-flex gap-3'>
                                                <div className='d-flex h-100vh justify-content-between align-items-start flex-column'>
                                                    <p className='color-dark medium-heading d-flex flex-wrap gap-sm-2 gap-1 align-items-center'>
                                                        <GrayUser />
                                                        {i.name}
                                                        <div>
                                                            <span className='squary-pill' style={{ backgroundColor: '#7C8692' }}></span>
                                                            <span className='color-purplr'>{i.date}</span>
                                                        </div>
                                                    </p>
                                                    <div>
                                                        <h4 className='medium-height-heading'>
                                                            {i.title}
                                                        </h4>
                                                        <p className='color-dark opacity-75 medium-heading mt-20'>
                                                            {i.description}
                                                        </p>
                                                        <div className='mt-30'>
                                                            <button className='gray-btn border-0' onClick={() => { navigate('/blog-detail') }}>
                                                                <span className='px-3'>
                                                                    READ MORE
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={i.img} alt="story img"
                                                    className='img-fluid blog_img' />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='col-12'>
                                    <CarGuide />
                                </div>
                                {sconddetail.map((i, index) =>
                                    <div className="col-lg-6" key={index}>
                                        <div className="story-border-card">
                                            <div className='d-flex gap-3'>
                                                <div className='d-flex h-100vh justify-content-between align-items-start flex-column'>
                                                    <p className='color-dark medium-heading d-flex flex-wrap gap-sm-2 gap-1 align-items-center'>
                                                        <GrayUser /> {i.name} <div>
                                                            <span className='squary-pill' style={{ backgroundColor: '#7C8692' }}></span>
                                                            <span className='color-purplr'>{i.date}</span>
                                                        </div>
                                                    </p>
                                                    <div>
                                                        <h4 className='medium-height-heading'>
                                                            {i.title}
                                                        </h4>
                                                        <p className='color-dark opacity-75 medium-heading mt-20'>
                                                            {i.description}
                                                        </p>
                                                        <div className='mt-30'>
                                                            <button className='gray-btn border-0' onClick={() => { navigate('/blog-detail') }}>
                                                                <span className='px-3'>
                                                                    READ MORE
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={i.img} alt="story img"
                                                    className='img-fluid blog_img' />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <SectionWaiting />
            <Footer />
        </>
    )
}
