import React from 'react'
import Box from './Box'
import { Heand, Messageicone, OnlyFb, OnlyTweet, PhoneIcone, Social } from './svg'
const showcard = [
    {
        box: [{ icon: <Messageicone />, bg: "#8860D0" }],
        title: 'Have questions? Just ask',
        discription: 'You can chat with us 24/7.',
        button: 'CHAT WITH US',
        link: '',
        icone: [{ icon: '', icon2: '' }]
    },
    {
        box: [{ icon: <PhoneIcone />, bg: "#F6AE2D" }],
        title: 'Ready to talk Now?',
        discription: 'Want to talk to someone over the phone? Give our customer experience center a call:',
        button: '(866) 629 - 0144',
        link: '',
        icone: [{ icon: '', icon2: '' }]
    },
    {
        box: [{ icon: <Heand />, bg: "#EF7733" }],
        title: 'Contact CarMax Auto Finance',
        discription: 'For questions about your CarMax Auto Finance account: Hours are Mon—Fri: 8am—10pm and Sat 9am—6pm ET',
        button: '(866) 629 - 0144',
        link: '',
        icone: [{ icon: '', icon2: '' }]
    }
    ,
    {
        box: [{ icon: <Social />, bg: "#555D91" }],
        title: 'Reach us on social media',
        discription: 'Our customer relations team is also happy to help via our social channels.',
        button: '',
        link: '',
        icone: [{ icon: <OnlyFb />, icon2: <OnlyTweet /> }]
    }
]
export default function CarHelpLine() {
    return (
        <div>
            <div className="row gap_30">
                {showcard.map((i, index) =>
                    <div className="col-md-6 col-12">
                        <div className='shadow-card'>
                            <Box icon={i.box[0].icon} Bgcolor={i.box[0].bg} />
                            <h6 className='color-dark sub-heading mt-50'>
                                {i.title}
                            </h6>
                            <span className='color-dark opacity-75 d-block'>
                                {i.discription}
                            </span>
                            <div className='d-block py-2'></div>
                            {i.button == '' ?
                                <div className="d-flex gap-2 mt-xx-5 mt-4 pt-sm-3 media_icon">
                                    <button className='gray-btn border-0 btn-blue'>
                                        <span className=''>
                                            {i.icone[0].icon}
                                        </span>
                                    </button>
                                    <button className='gray-btn border-0 btn-black'>
                                        <span className=''>
                                            {i.icone[0].icon2}
                                        </span>
                                    </button>
                                </div>
                                :
                                <button className='gray-btn border-0 mt-xxl-5 mt-4'>
                                    <span className=''>
                                        {i.button}
                                    </span>
                                </button>
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
