import React from "react";

export default function StepProgress({ inputs, currentStep }) {
  return (
    <>
      <div className="progress-vehicle-show text-end">
        <h4>
          {inputs.modelyear} {inputs.make} {inputs.model}
        </h4>
        <img
          src={inputs.image || `/assets/img/dashboard/car.webp`}
          alt="car"
          className="img-fluid car_img"
        />
      </div>
      <div className="progress-card mt-30">
        <div className="position-relative">
          <div
            className="progress"
            role="progressbar"
            aria-label="Progress"
            aria-valuenow={50}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: 1, height: "130px" }}
          >
            <div className="progress-bar" style={{ width: "100%" }} />
          </div>
          <button
            type="button"
            className={`position-absolute top-0 start-0 translate-middle btn btn-sm  rounded-pill${
              currentStep > 1
                ? " isactive"
                : currentStep == 1
                ? " isrunning"
                : ""
            }`}
            style={{ width: "20px", height: "20px" }}
          ></button>
          <button
            type="button"
            className={`position-absolute top-25 start-0 translate-middle btn btn-sm  rounded-pill${
              currentStep > 2
                ? " isactive"
                : currentStep == 2
                ? " isrunning"
                : ""
            }`}
            style={{ width: "20px", height: "20px" }}
          ></button>
          <button
            type="button"
            className={`position-absolute top-75 start-0 translate-middle btn btn-sm  rounded-pill${
              currentStep > 3
                ? " isactive"
                : currentStep == 3
                ? " isrunning"
                : ""
            }`}
            style={{ width: "20px", height: "20px" }}
          ></button>
          <button
            type="button"
            className={`position-absolute top-100 start-0 translate-middle btn btn-sm  rounded-pill${
              currentStep > 4
                ? " isactive"
                : currentStep == 4
                ? " isrunning"
                : ""
            }`}
            style={{ width: "20px", height: "20px" }}
          ></button>
          <div className="position-absolute  ms-4 question-card ">
            <span className="questions ">Select your car's style</span>
            <span className="questions">Select your car's features</span>
            <span className="questions">
              What color is your vehicle's exterior and interior?
            </span>
            <span className="questions mb-0">
              What is the condition of the car?
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
